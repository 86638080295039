.categories {
    margin-top: 1.0em;
    margin-bottom: 2.5em;
}

.btnCategory {
    margin-top: 1em;
    margin-bottom: 1em;
}

.btnArticle {
    margin-left: 1em;
}