/* App.css */
html,
#root {
  background-color: #f3f2f1;
  --color-primary: #5c5c5b; 
}

.button.is-primary[disabled],
fieldset[disabled] .button.is-primary {
  background-color: #a2b013 !important;
}

body {
  color: #252423;
}