.statusSend {
    background-color: #fffbeb;
    color: #947600;
}
.statusOrdered {
    background-color: #effaf3;
    color: #257942;
}
.statusCanceled {
    background-color: #feecf0;
    color: #cc0f35;
}
.statusArchived {
    background-color: #363636;
    border-color: #363636;
    color: #fff;
}